import gql from "graphql-tag";


// Catgeories

export const CategoriesQuery = gql `
query Categories($lang: I18NLocaleCode) {
  categories(locale:$lang) {
    data {
      id
      attributes {
        title
        subtitle
        seo {
          permalink
        }
      }
    }
  }
}
`

// Page category

const CARD_BLOG = gql `
fragment CardBlog on CategoryPageContentDynamicZone {
    ... on ComponentBlogCardBlog {
      __typename
      id
      title
      article {
        data {
          id
          attributes {
            title
            subtitle
            discription
            image {
              data {
                attributes {
                  url
                }
              }
            }
            seo {
              permalink
            }
          }
        }
      }
    }
  }
  
`;

const ALL_BLOGS = gql `
fragment AllBlogs on CategoryPageContentDynamicZone {
    ... on ComponentBlogAllBlogs {
      id
      title
    }
  }
`;

const BANNER = gql `
fragment Banner3 on CategoryPageContentDynamicZone {
    ... on ComponentGlobalBanner {
      id
      title
      items {
        id
        title
        subTitle
        image {
          data {
            attributes {
              url
            }
          }
        }
        link
        link_text
        type
        video_url
      }
    }
  }
`

export const CategoryPage = gql `
${CARD_BLOG}
${ALL_BLOGS}
${BANNER}
query pageCategory($lang: I18NLocaleCode) {
    categoryPage(locale:$lang) {
      data {
        id
        attributes {
          title
          subTitle
          discription
          content {
            ...CardBlog
            ...AllBlogs
            ...Banner3
          }
        }
      }
    }
  }
`
