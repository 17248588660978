import gql from "graphql-tag";

// get article (permalink)
const CONTENT_FRAGMENT = gql`
  fragment ContentFields on ArticleContentDynamicZone {
    ... on ComponentBlogMediaContent {
      __typename
      id
      type
      title
      body
      image {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
`;
const OTHER_FRAGMENT = gql`
  fragment OtherFields on ArticleContentDynamicZone {
    ... on ComponentBlog3Blog {
      __typename
      id
      title
      subTitle
      cta {
        text
        url
      }
    }
  }
`;
const BANNER = gql`
  fragment Banner2 on ArticleContentDynamicZone {
    ... on ComponentGlobalBanner {
      __typename
      id
      title
      items {
        id
        title
        subTitle
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
        link
        type
        video_url
      }
    }
  }
`;
export const BlogQuery = gql`
  ${CONTENT_FRAGMENT}
  ${OTHER_FRAGMENT}
  ${BANNER}
  query getArticle($lang: I18NLocaleCode, $permalink: String) {
    articles(
      locale: $lang,
      filters: { seo: { permalink: { eq: $permalink } } }
    ) {
      data {
        id
        attributes {
          title
          subtitle
          discription
          image {
            data {
              attributes {
                url
              }
            }
          }
          seo {
            permalink
          }
          content {
            ...ContentFields
            ...OtherFields
            ...Banner2
          }
          categories {
            data {
              id
              attributes {
                title
                subtitle
              }
            }
          }
          locale
          localizations {
            data {
              attributes {
                seo {
                  permalink
                }
                locale
              }
            }
          }
        }
      }
    }
  }
`;

// article withe pagination

export const ArticlesPerCategory = gql`
  ${CONTENT_FRAGMENT}
  ${OTHER_FRAGMENT}
  ${BANNER}
  query Category($lang: I18NLocaleCode, $permalink: String, $start:Int, $limit: Int) {
    categories(
      locale: $lang
      filters: { seo: { permalink: { eq: $permalink } } }
    ) {
      data {
        id
        attributes {
          title
          subtitle
          seo {
            permalink
          }
          locale
          localizations {
            data {
              attributes {
                seo {
                  permalink
                }
                locale
              }
            }
          }
          articles(pagination: { start: $start, limit: $limit }) {
            data {
              id
              attributes {
                title
                subtitle
                discription
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                seo {
                  permalink
                }
                content {
                  ...ContentFields
                  ...OtherFields
                  ...Banner2
                }
                categories {
                  data {
                    id
                    attributes {
                      title
                      subtitle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Catgeories

export const CategoriesQuery = gql`
  query Categories {
    categories {
      data {
        id
        attributes {
          title
          subtitle
        }
      }
    }
  }
`;
